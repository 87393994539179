{
  const typingInterval = 200;

  async function yourDomain() {
    if (!$('.deploy.active')) {
      await utils.wait(500);
      return;
    }
    const domainFeat = $('#domainFeat');
    const text = $('.text .inner', domainFeat);

    await utils.eraseAndType(text, 'my.com', typingInterval);
    await utils.wait(5000);
  }
  (function yourDomainLooped() {
    Promise.resolve()
      .then(() => yourDomain())
      .then(() => yourDomainLooped());
  })();
}
